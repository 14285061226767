import { useRef, useState } from "react";

export default function useFormValidation(
  selectedCourt,
  courtNumber,
  nameOfPlaintiff,
  addressOfPlaintiff,
  lawyerOfPlaintiff,
  nameOfDefendant,
  addressOfDefendant,
  lawyerOfDefendant,
  selectedStartDate
) {
  const [errorCourtNumber, setErrorCourtNumber] = useState("");
  const [errorNameOfPlaintiff, setErrorNameOfPlaintiff] = useState("");
  const [errorAddressOfPlaintiff, setErrorAddressOfPlaintiff] = useState("");
  const [errorLawyerOfPlaintiff, setErrorLawyerOfPlaintiff] = useState("");
  const [errorCvrOfPlaintiff, setErrorCvrOfPlaintiff] = useState("");
  const [errorNameOfDefendant, setErrorNameOfDefendant] = useState("");
  const [errorAddressOfDefendant, setErrorAddressOfDefendant] = useState("");
  const [errorLawyerOfDefendant, setErrorLawyerOfDefendant] = useState("");
  const [errorCvrOfDefendant, setErrorCvrOfDefendant] = useState("");
  const [errorHighCourtText, setErrorHighCourtText] = useState("");

  const [dataAndTimeError, setDataAndTimeError] = useState("");
  const [companyLogoError, setCompanyLogoError] = useState("");

  const courtNumberRef = useRef(null);
  const nameOfPlaintiffRef = useRef(null);
  const addressOfPlaintiffRef = useRef(null);
  const lawyerOfPlaintiffRef = useRef(null);
  const cvrOfPlaintiffRef = useRef(null);
  const nameOfDefendantRef = useRef(null);
  const addressOfDefendantRef = useRef(null);
  const lawyerOfDefendantRef = useRef(null);
  const cvrOfDefendantRef = useRef(null);
  const highCourtTextRef = useRef(null);

  const dataAndTimeRef = useRef(null);
  const companyLogoRef = useRef(null);

  const headerItemRef = useRef(null);
  const headerItemsRef = useRef([]);

  const clearErrorStates = () => {
    setErrorCourtNumber("");
    setErrorNameOfPlaintiff("");
    setErrorAddressOfPlaintiff("");
    setErrorLawyerOfPlaintiff("");
    setErrorNameOfDefendant("");
    setErrorAddressOfDefendant("");
    setErrorLawyerOfDefendant("");
    setErrorHighCourtText("");

    setDataAndTimeError("");
    setCompanyLogoError("");
  };

  const checkFields = (isValid) => {    
    if (selectedCourt === "") {
      isValid = false;
    }

    if (courtNumber === "" || !courtNumber) {
      isValid = false;
      setErrorCourtNumber("Required");
    }

    if (nameOfPlaintiff === "" || !nameOfPlaintiff) {
      isValid = false;
      setErrorNameOfPlaintiff("Required");
    }

    if (addressOfPlaintiff === "" || !addressOfPlaintiff) {
      isValid = false;
      setErrorAddressOfPlaintiff("Required");
    }

    if (lawyerOfPlaintiff === "" || !lawyerOfPlaintiff) {
      isValid = false;
      setErrorLawyerOfPlaintiff("Required");
    }

    if (nameOfDefendant === "" || !nameOfDefendant) {
      isValid = false;
      setErrorNameOfDefendant("Required");
    }

    if (addressOfDefendant === "" || !addressOfDefendant) {
      isValid = false;
      setErrorAddressOfDefendant("Required");
    }

    if (lawyerOfDefendant === "" || !lawyerOfDefendant) {
      isValid = false;
      setErrorLawyerOfDefendant("Required");
    }

    if (selectedCourt === "National" && !selectedStartDate) {
      isValid = false;
      setDataAndTimeError("Required");
    }
  }

  const validateForm = () => {
    let isValid = true;

    clearErrorStates();

    checkFields();

    if (courtNumber === "" || !courtNumber) {
      if (courtNumberRef.current) {
        courtNumberRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
    }

    if (nameOfPlaintiff === "" || !nameOfPlaintiff) {
      if (nameOfPlaintiffRef.current) {
        nameOfPlaintiffRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
    }

    if (addressOfPlaintiff === "" || !addressOfPlaintiff) {
      if (addressOfPlaintiffRef.current) {
        addressOfPlaintiffRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
    }

    if (lawyerOfPlaintiff === "" || !lawyerOfPlaintiff) {
      if (lawyerOfPlaintiffRef.current) {
        lawyerOfPlaintiffRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
    }

    if (nameOfDefendant === "" || !nameOfDefendant) {
      if (nameOfDefendantRef.current) {
        nameOfDefendantRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
    }

    if (addressOfDefendant === "" || !addressOfDefendant) {
      if (addressOfDefendantRef.current) {
        addressOfDefendantRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
    }

    if (lawyerOfDefendant === "" || !lawyerOfDefendant) {
      if (lawyerOfDefendantRef.current) {
        lawyerOfDefendantRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
    }

    if (selectedCourt === "National" && !selectedStartDate) {
      if (dataAndTimeRef.current) {
        dataAndTimeRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
    }

    return isValid;
  };

  return {
    errorCourtNumber,
    errorNameOfPlaintiff,
    errorAddressOfPlaintiff,
    errorLawyerOfPlaintiff,
    errorCvrOfPlaintiff,
    errorNameOfDefendant,
    errorAddressOfDefendant,
    errorLawyerOfDefendant,
    errorCvrOfDefendant,
    errorHighCourtText,
    dataAndTimeError,
    companyLogoError,
    courtNumberRef,
    nameOfPlaintiffRef,
    addressOfPlaintiffRef,
    lawyerOfPlaintiffRef,
    cvrOfPlaintiffRef,
    nameOfDefendantRef,
    addressOfDefendantRef,
    lawyerOfDefendantRef,
    cvrOfDefendantRef,
    highCourtTextRef,
    dataAndTimeRef,
    companyLogoRef,
    headerItemRef,
    headerItemsRef,
    clearErrorStates,
    validateForm,
  };
}
