import CustomButton from "__utilities/components/CustomButton";
import AppTooltip from "__utilities/components/Tooltip";
import { extractContext } from "_data/context/extract";
import { overlayContext } from "_data/context/overlay";
import ErrorPopup from "_features/ErrorPopup";
import LoadingPopup from "_features/LoadingPopup";
import UploadPDF from "_features/UploadPDF";
import { logEvent } from "analytics/analytics";
import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";

export default function ChooseTypeView() {
  const fileUploadRef = useRef();
  const [percentage, setPercentage] = useState(null);
  const navigate = useNavigate();
  const {
    uploadFile,
    chunkSize,
    currentChunkIndex,
    processing,
    setSelectedCourt,
    setCurrentChunkIndex,
    setBlockTypeSwitch,
    setUploadFile,
    uploadSuccess,
    uploadFileErrorMessage,
    setUploadFileErrorMessage,
    clearSessionStorage,
  } = useContext(extractContext);

  const { setOverlay } = useContext(overlayContext);
  const [showCourts, setShowCourts] = useOutletContext();
  const [showHoverText, setShowHoverText] = useState(false);

  const onExtractHandler = () => {
    if (uploadSuccess) {
      setBlockTypeSwitch(true);
      setOverlay({
        show: false,
        content: <></>,
      });
      navigate("/home");
    } else {
      setOverlay({
        show: true,
        content: (
          <ErrorPopup
            text={"Der opstod en fejl under udpakning af PDF-fil"}
            subtext={uploadFileErrorMessage ? uploadFileErrorMessage : ""}
            onClose={() => {
              setUploadFileErrorMessage("");
              setOverlay({ show: false, content: <></> });
            }}
          />
        ),
      });
    }
  };

  useEffect(() => {
    if (uploadSuccess !== undefined && uploadSuccess !== null) {
      onExtractHandler();
    }
  }, [uploadSuccess]);

  useEffect(() => {
    if (uploadFile) {
      setPercentage(
        (
          ((currentChunkIndex + 1) / Math.ceil(uploadFile.size / chunkSize)) *
          100
        ).toFixed(0)
      );
      setOverlay({
        show: true,
        content: (
          <LoadingPopup percentage={percentage} processing={processing} />
        ),
      });
      if (currentChunkIndex === null && processing === null) {
        setOverlay({
          show: false,
          content: <></>,
        });
      } else {
      }
    }
  }, [currentChunkIndex, uploadFile, processing]);

  const onUploadedPDF = (files) => {
    setUploadFile(files[0]);
    setCurrentChunkIndex(0);
  };

  const onChangeExistingExtract = () => {
    logEvent("edit_extract","app_extract_edit","edit_button");
    setOverlay({
      show: true,
      content: (
        <div className="w-[33%]">
          <UploadPDF
            onUploadedFile={onUploadedPDF}
            ref={fileUploadRef}
            multipleSelect={false}
            styles="py-[78px]"
          />
        </div>
      ),
    });
  };

  const onChangeExistingExtractMouseEnter = () => {
    setShowHoverText(true);
  };

  const onChangeExistingExtractMouseLeave = () => {
    setShowHoverText(false);
  };

  return (
    <div className="w-full h-full flex justify-center items-center p-[50px]">
      <div className="w-full h-full flex flex-col justify-center items-center bg-primaryBlue bg-opacity-[6%]">
        {!showCourts ? (
          <div className="w-1/4 flex flex-col items-center justify-center">
            <div className="text-title text-primaryBlue mb-[30px]">
              Jeg vil gerne
            </div>
            <CustomButton
              text="Oprette ny ekstrakt"
              styles="mb-[16px]"
              onClick={() => setShowCourts(true)}
            ></CustomButton>
            <CustomButton
              text="Ændre eksisterende ekstrakt"
              onClick={onChangeExistingExtract}
              onMouseEnter={onChangeExistingExtractMouseEnter}
              onMouseLeave={onChangeExistingExtractMouseLeave}
            />
          </div>
        ) : (
          <div className="w-1/4 flex flex-col items-center justify-center gap-y-4">
            <div className="text-title text-primaryBlue mb-[30px]">Vælg</div>
            <CustomButton
              text="Byret"
              onClick={() => {
                logEvent("open_city_extract","app_extract_start","city_button");
                clearSessionStorage();
                setSelectedCourt("City");
                setShowCourts(false);
                navigate("/home");
              }}
            ></CustomButton>
            <CustomButton
              text="Landsret"
              onClick={() => {
                logEvent("open_national_extract","app_extract_start","national_button");
                clearSessionStorage();
                setSelectedCourt("National");
                setShowCourts(false);
                navigate("/home");
              }}
            />
            <CustomButton
              text="Højesteret"
              onClick={() => {
                logEvent("open_supreme_extract","app_extract_start","supreme_button");
                clearSessionStorage();
                setSelectedCourt("Supreme");
                setShowCourts(false);
                navigate("/home");
              }}
            />
          </div>
        )}

        <div className="h-[50px] mt-2">
          {showHoverText && (
            <span className="italic">
              (Du kan alene ændre ekstrakter med denne funktion, hvis din
              ekstrakt tidligere er genereret med NemEkstrakt)
            </span>
          )}
        </div>
      </div>
      <AppTooltip
        componentIds={["national-court-tooltip", "supreme-court-tooltip"]}
      />
    </div>
  );
}
