import DroppablePlaceholder from "__utilities/components/DroppablePlaceholder";
import ListElement from "__utilities/components/ListElement";
import ListElementPlaceholder from "__utilities/components/ListElementPlaceholder";
import React from "react";
import { Droppable } from "react-beautiful-dnd";

const DroppableItem = React.forwardRef(
  (
    {
      droppableId,
      item,
      isSelected,
      styles,
      hasPlaceholder,
      userInfo,
      onItemDelete,
      error,
    },
    ref
  ) => (
    <div
      ref={ref}
      className={`bg-white border-dashed border-[1px] mb-[8px] ${
        error ? "border-red-500" : ""
      }`}
    >
      <Droppable droppableId={droppableId} isDropDisabled={isSelected}>
        {(provided, snapshot) => {
          return (
            <div
              className={`min-h-[50px] ${styles}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {isSelected ? (
                <ListElement
                  key={item.id}
                  name={item.name}
                  index={item.index}
                  text={item.fileName}
                  id={item.id}
                  styles="border-[0px]"
                  isUploaded={true}
                  isDragDisabled={true}
                  isEditDisabled={true}
                  onDelete={() => onItemDelete(item.id)}
                  hasDate={false}
                  userInfo={userInfo}
                />
              ) : (
                !snapshot.isDraggingOver && (
                  <ListElementPlaceholder
                    text={item?.name}
                    styles="mt-[8px] border-[0px]"
                  />
                )
              )}
              {snapshot.isDraggingOver && <DroppablePlaceholder />}
            </div>
          );
        }}
      </Droppable>
    </div>
  )
);

export default DroppableItem;