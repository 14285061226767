import DroppablePlaceholder from "__utilities/components/DroppablePlaceholder";
import ListElement from "__utilities/components/ListElement";
import ListElementPlaceholder from "__utilities/components/ListElementPlaceholder";
import ListHeader from "__utilities/components/ListHeader";
import React, { Fragment, useState } from "react";
import { Droppable } from "react-beautiful-dnd";

const DroppableList = React.forwardRef(
	(
	  {
	droppableId,
	fileList,
	header,
	isSortable,
	onSortClick,
	styles,
	hasPlaceholder,
	userInfo,
	onItemDelete,
	onItemEdit,
	elementsHasError,
	listHasError,
	listHasDate,
	  },
	  ref
	) => {
		const [isOpen, setIsOpen] = useState(true);

		const content = () => (
			<div ref={ref}>
				<Droppable  droppableId={droppableId}>
				{(provided, snapshot) => {
					return (
						<div
							className={`min-h-[50px] ${styles}`}
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{fileList?.map((file, index) => (
								<ListElement
									key={file.id}
									name={file.name}
									date={file.date}
									index={index}
									id={file.id}
									styles="mt-[8px]"
									onDelete={() => {
										onItemDelete(file.id);
									}}
									onEdit={(value, date) => onItemEdit(file.id, value, date)}
									error={!file.isValid || (elementsHasError && !file.date)} // date validation
									isDragDisabled={!file.isValid || !file.isUploaded}
									isEditDisabled={!file.isValid || !file.isUploaded}
									isUploaded={file.isUploaded}
									isTextSearchable={file.isTextSearchable}
									license={userInfo?.license}
									currentProgress={file.currentChunkIndex}
									totalProgress={file.totalChunks}
									errorDetails={file.error}
									hasDate={listHasDate}
								/>
							))}
							{snapshot.isDraggingOver && <DroppablePlaceholder />}
							{hasPlaceholder && <ListElementPlaceholder styles="mt-[8px]" />}
						</div>
					);
				}}
			</Droppable>
			</div>
			
		);
	
		return (
			<Fragment>
				{header ? (
					<div className="flex flex-col mb-[8px]">
						<ListHeader
							isSortable={isSortable}
							onSortClick={() => onSortClick(header)}
							name={header}
							onOpenClose={() => setIsOpen((prev) => !prev)}
							isOpen={isOpen}
							error={listHasError}
						/>
						{isOpen && (
							<div className="flex justify-end">
								<div className="w-[97%]">{content()}</div>
							</div>
						)}
					</div>
				) : (
					content()
				)}
			</Fragment>
		);
	}
)

export default DroppableList;
