import Popup from "__utilities/components/Popup";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { downloadPDFFile } from "__utilities/functions/public";
import { AppConfig } from "AppConfig";
import { ProgressStepper, ProgressStep } from "@patternfly/react-core";
import InProgressIcon from "@patternfly/react-icons/dist/esm/icons/in-progress-icon";
import PendingIcon from "@patternfly/react-icons/dist/esm/icons/pending-icon";
import SuccessIcon from "@patternfly/react-icons/dist/esm/icons/check-circle-icon";
import ErrorIcon from "@patternfly/react-icons/dist/esm/icons/warning-triangle-icon";
import "@patternfly/react-core/dist/styles/base.css";
import { overlayContext } from "_data/context/overlay";
import { logEvent } from "analytics/analytics";

export default function DownloadPopup({ progress, clearSessionStorage }) {
  const {setOverlay} = useContext(overlayContext);
  const navigate = useNavigate();

  const refreshPage = () => {
    navigate("/");
    window.location.reload();
  };
  const getVariant = (status) => {
    switch (status) {
      case "Completed":
        return "success";
      case "Pending":
        return "pending";
      case "Failed":
        return "danger";
      default:
        return "default";
    }
  };

  const getIcon = (status) => {
    switch (status) {
      case "Completed":
        return <SuccessIcon />;
      case "Failed":
        return <ErrorIcon />;
      case "InProgress":
        return <InProgressIcon />;
      case "Pending":
        return <PendingIcon />;
      default:
        return <></>;
    }
  };

  return (
    <Popup styles={"w-[50%] tall:w-[40%] h-[40%] py-[20px]"}>
      <div className="flex flex-col w-full h-full justify-center items-center relative">
        {progress.status === 'Failed' && <div className="flex flex-col absolute z-50 top-0 right-0 mr-4 cursor-pointer" onClick={() => setOverlay({show:false, content: <></>})}>
          <img className="content-closeIcon scale-125" alt="" />
        </div>}

        <div className="h-[1px] bg-[#F0F3F5] my-[20px]" />

        <div className="flex flex-col w-full items-center justify-center">
          <ProgressStepper
            isCenterAligned={true}
            className="pf-m-show-progress"
          >
            {progress.steps.map((step) => (
              <ProgressStep
                key={step.number}
                isCurrent={step.status === "InProgress"}
                variant={`${getVariant(step.status)}`}
                icon={getIcon(step.status)}
                id="custom-step1"
                description={
                  step.status === "InProgress" ? step.description : ""
                }
                className={
                  step.status === "InProgress"
                    ? "pf-v5-c-progress-stepper__step-icon-rotating"
                    : ""
                }
                style={{
                  "--pf-v5-c-progress-stepper__step-percent":
                    step.status === "InProgress" ? step.percentage : 0,
                }}
              >
                {step.title}
              </ProgressStep>
            ))}

            <ProgressStep
              variant={`${getVariant(progress.status)}`}
              icon={
                progress.status === "Completed"
                  ? getIcon("Completed")
                  : getIcon("Pending")
              }
              id="custom-step5"
            >
              Færdig
            </ProgressStep>
          </ProgressStepper>
        </div>

        {progress.status === 'Failed' && <div className="mt-10 text-red-600">
          <p>{progress.errorMessage}</p>
        </div>}

        {progress?.token && (
          <div
            onClick={() => {
              logEvent("download_new_extract","app_extract_download","download_button");
              window.onbeforeunload = null;
              downloadPDFFile(
                `${AppConfig.SERVICE_BASE_URL}/api/v1/extract/download/${progress.token}`
              );
              clearSessionStorage();
              refreshPage();
            }}
            className="cursor-pointer underline mt-10 text-[#C58B68] flex"
          >
            Klik her for at downloade
            <img className="content-downloadIcon" alt="" />
          </div>
        )}
      </div>
    </Popup>
  );
}
