import CustomButton from "__utilities/components/CustomButton";
import Popup from "__utilities/components/Popup";

export default function ErrorPopup({text, subtext, onClose}) {
  return (
    <Popup>
      <div className="text-red-500 font-latoBold mb-[10px]">
        {text}
      </div>
      {subtext && 
        <div className="text-red-500 font-latoLight font-standardSmall m-[30px] mt-[20px] text-justify">
          {subtext}
        </div>
      }
      <CustomButton text={"Luk"} onClick={onClose} styles="w-[60%] py-[8px]"/>
    </Popup>
  )
}
