import InputField from "__utilities/components/InputField";
import { extractContext } from "_data/context/extract";
import { useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Section from "__utilities/components/Section";

const CaseInfoForm = () => {
	const {
		errorCourtNumber,
		errorNameOfPlaintiff,
		errorAddressOfPlaintiff,
		errorLawyerOfPlaintiff,
		errorCvrOfPlaintiff,
		errorNameOfDefendant,
		errorAddressOfDefendant,
		errorLawyerOfDefendant,
		errorCvrOfDefendant,
		errorHighCourtText,
		dataAndTimeError,

		courtNumberRef,
		nameOfPlaintiffRef,
		addressOfPlaintiffRef,
		lawyerOfPlaintiffRef,
		cvrOfPlaintiffRef,
		nameOfDefendantRef,
		addressOfDefendantRef,
		lawyerOfDefendantRef,
		cvrOfDefendantRef,
		highCourtTextRef,
		dataAndTimeRef,
		companyLogoRef,

		courtNumber,
		setCourtNumber,
		nameOfPlaintiff,
		setNameOfPlaintiff,
		cvrOfPlaintiff,
		setCvrOfPlaintiff,
		addressOfPlaintiff,
		setAddressOfPlaintiff,
		lawyerOfPlaintiff,
		setLawyerOfPlaintiff,
		nameOfDefendant,
		setNameOfDefendant,
		addressOfDefendant,
		setAddressOfDefendant,
		lawyerOfDefendant,
		setLawyerOfDefendant,
		highCourtText,
		setHighCourtText,
		cvrOfDefendant,
		setCvrOfDefendant,
		isPlaintiffLawyerAppointed,
		setIsPlaintiffLawyerAppointed,
		isDefendantLawyerAppointed,
		setIsDefendantLawyerAppointed,
		numberOfJudges,
		setNumberOfJudges,

		selectedStartDate,
		setSelectedStartDate,
		selectedCourt,
	} = useContext(extractContext);

	const [isPlaintiffSectionOpen, setIsPlaintiffSectionOpen] = useState(false);
	const [isDefendantSectionOpen, setIsDefendantSectionOpen] = useState(false);

	const startDateRef = useRef();

	const getPlaintiffLabel = () => {
		if (selectedCourt === "Supreme") {
			return "Appellantens navn:";
		} else {
			return selectedCourt === "National"
				? "Appellantens navn:"
				: "Sagsøgers navn:";
		}
	};

	const getDefendantLabel = () => {
		if (selectedCourt === "Supreme") {
			return "Indstævntes navn:";
		} else {
			return selectedCourt === "National"
				? "Instævntes navn:"
				: "Sagsøgtes navn:";
		}
	};

	useEffect(() => {
		if (startDateRef.current) {
			startDateRef.current.setOpen(false);
		  }
	  },[selectedStartDate])

	return (
		<div className="flex flex-col gap-[8px] mt-[14px]">
			<InputField
				name={
					selectedCourt === "Supreme"
						? "Højesterets sagsnummer og afdeling:"
						: "Rettens sagsnummer:"
				}
				placeholder="Indtast venligst"
				selectedCourt={selectedCourt}
				value={courtNumber}
				onChange={setCourtNumber}
				error={errorCourtNumber}
				ref={courtNumberRef}
			/>
			<InputField
				name={getPlaintiffLabel()}
				placeholder="Indtast venligst"
				selectedCourt={selectedCourt}
				value={nameOfPlaintiff}
				onChange={setNameOfPlaintiff}
				error={errorNameOfPlaintiff}
				ref={nameOfPlaintiffRef}
			/>
			<InputField
				name={
					selectedCourt === "Supreme"
						? "Appellantens CVR.nr.:"
						: `${
								selectedCourt === "National" ? "Appellantens" : "Sagsøgers"
						  } CVR.nr.:`
				}
				placeholder="Indtast venligst"
				selectedCourt={selectedCourt}
				value={cvrOfPlaintiff}
				onChange={setCvrOfPlaintiff}
				error={errorCvrOfPlaintiff}
				ref={cvrOfPlaintiffRef}
			/>
			<InputField
				name={
					selectedCourt === "Supreme"
						? "Appellantens adresse:"
						: `${
								selectedCourt === "National" ? "Appellantens" : "Sagsøgers"
						  } adresse:`
				}
				placeholder="Indtast venligst"
				selectedCourt={selectedCourt}
				value={addressOfPlaintiff}
				onChange={setAddressOfPlaintiff}
				error={errorAddressOfPlaintiff}
				ref={addressOfPlaintiffRef}
			/>
			<InputField
				name={
					selectedCourt === "Supreme"
						? "Appellantens advokat:"
						: `${
								selectedCourt === "National" ? "Appellantens" : "Sagsøgers"
						  } advokat:`
				}
				placeholder="Indtast venligst"
				selectedCourt={selectedCourt}
				value={lawyerOfPlaintiff}
				onChange={setLawyerOfPlaintiff}
				error={errorLawyerOfPlaintiff}
				ref={lawyerOfPlaintiffRef}
			/>
			<InputField
				name={getDefendantLabel()}
				placeholder="Indtast venligst"
				selectedCourt={selectedCourt}
				value={nameOfDefendant}
				onChange={setNameOfDefendant}
				error={errorNameOfDefendant}
				ref={nameOfDefendantRef}
			/>
			<InputField
				name={
					selectedCourt === "Supreme"
						? "Indstævntes eventuelle CVR.nr.:"
						: `${
								selectedCourt === "National" ? "Instævntes" : "Sagsøgtes"
						  } CVR.nr.:`
				}
				placeholder="Indtast venligst"
				selectedCourt={selectedCourt}
				value={cvrOfDefendant}
				onChange={setCvrOfDefendant}
				error={errorCvrOfDefendant}
				ref={cvrOfDefendantRef}
			/>
			<InputField
				name={
					selectedCourt === "Supreme"
						? "Indstævntes adresse:"
						: `${
								selectedCourt === "National" ? "Instævntes" : "Sagsøgtes"
						  } adresse:`
				}
				placeholder="Indtast venligst"
				selectedCourt={selectedCourt}
				value={addressOfDefendant}
				onChange={setAddressOfDefendant}
				error={errorAddressOfDefendant}
				ref={addressOfDefendantRef}
			/>
			<InputField
				name={
					selectedCourt === "Supreme"
						? "Indstævntes advokat:"
						: `${
								selectedCourt === "National" ? "Instævntes" : "Sagsøgtes"
						  } advokat:`
				}
				placeholder="Indtast venligst"
				selectedCourt={selectedCourt}
				value={lawyerOfDefendant}
				onChange={setLawyerOfDefendant}
				error={errorLawyerOfDefendant}
				ref={lawyerOfDefendantRef}
			/>
			{selectedCourt === "National" && (
				<InputField
					name="Tekst i bunden af forside"
					placeholder="Indtast venligst"
					selectedCourt={selectedCourt}
					value={highCourtText}
					onChange={setHighCourtText}
					error={errorHighCourtText}
					styles="h-[80px]"
				/>
			)}
			<InputField
				name="Dato for hovedforhandling:"
				error={dataAndTimeError}
				selectedCourt={selectedCourt}
				floatingContent={
					<div>
						<DatePicker
							dateFormat="dd/MM/yyyy"
							selected={selectedStartDate}
							onChange={(date) => setSelectedStartDate(date)}
							ref={startDateRef}
						/>
					</div>
				}
				onClick={() => {
					startDateRef.current.setFocus();
				}}
				ref={dataAndTimeRef}
			/>
			{selectedCourt === "Supreme" ? (
				<>
					<InputField
						name="Antallet af deltagende dommere:"
						selectedCourt="Supreme"
						placeholder="Indtast venligst"
						value={numberOfJudges}
						onChange={setNumberOfJudges}
					/>
					<div className="bg-white w-fit p-[10px] border-[1px] border-textLightGray">
						<Section
							isOpen={isPlaintiffSectionOpen}
							setIsOpen={setIsPlaintiffSectionOpen}
							title={`Er appellantens advokat beskikket - ${
								isPlaintiffLawyerAppointed ? "Ja" : "Nej"
							}`}
							titleFont="text-standard"
							borderVisibleWhenCloased={false}
						>
							{["Ja", "Nej"].map((option) => (
								<div
									key={option}
									onClick={() => {
										const isAppointed = option === "Ja";
										setIsPlaintiffLawyerAppointed(isAppointed);
										setIsPlaintiffSectionOpen(!isPlaintiffSectionOpen);
									}}
									className={`p-[4px] ${
										option === (isPlaintiffLawyerAppointed ? "Ja" : "Nej")
											? "bg-primaryBlue text-white"
											: ""
									}`}
								>
									{option}
								</div>
							))}
						</Section>
					</div>

					<div className="bg-white w-fit p-[10px] border-[1px] border-textLightGray">
						<Section
							isOpen={isDefendantSectionOpen}
							setIsOpen={setIsDefendantSectionOpen}
							title={`Er indstævntes advokat beskikket - ${
								isDefendantLawyerAppointed ? "Ja" : "Nej"
							}`}
							titleFont="text-standard"
							borderVisibleWhenCloased={false}
						>
							{["Ja", "Nej"].map((option) => (
								<div
									key={option}
									onClick={() => {
										const isAppointed = option === "Ja";
										setIsDefendantLawyerAppointed(isAppointed);
										setIsDefendantSectionOpen(!isDefendantSectionOpen);
									}}
									className={`p-[4px] ${
										option === (isDefendantLawyerAppointed ? "Ja" : "Nej")
											? "bg-primaryBlue text-white"
											: ""
									}`}
								>
									{option}
								</div>
							))}
						</Section>
					</div>
				</>
			) : (
				""
			)}
		</div>
	);
};

export default CaseInfoForm;
