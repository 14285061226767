import { getUuid } from "__utilities/functions/public";
import { extractContext } from "_data/context/extract";
import DroppableList from "_features/DroppableList";
import UploadPDF from "_features/UploadPDF";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import ExtractContent from "./PageComponents/ExtractContent";
import {
  addToSession,
  getFromSession,
} from "__utilities/functions/sessionHandler";
import useDidMountEffect from "__utilities/functions/useDidMount";
import { overlayContext } from "_data/context/overlay";
import { useNavigate } from "react-router-dom";
import { navigateBack } from "_navigation/manager";

export default function HomepageView() {
  const navigate = useNavigate();
  const uploadFileRef = useRef();

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [showFileErrors, setShowFileErrors] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const chunkSize = 10 * 1024 * 1024;

  const {
    margeDocuments,
    headers,
    setHeaders,
    getHeaders,
    userInfo,
    setUserInfo,
    courtNumber,
    nameOfPlaintiff,
    cvrOfPlaintiff,
    addressOfPlaintiff,
    lawyerOfPlaintiff,
    nameOfDefendant,
    addressOfDefendant,
    lawyerOfDefendant,
    highCourtText,
    cvrOfDefendant,
    isPlaintiffLawyerAppointed,
    isDefendantLawyerAppointed,
    numberOfJudges,
    selectedStartDate,
    selectedImage,
    selectedLogoPosition,
    selectedPdf,
    addTimeline,
    printBothSide,
    showCaseInfoForm,
    setShowCaseInfoForm,
    showAdvancedForm,
    setShowAdvancedForm,
    headerItemsRef,
    isCaseInfoFormFilled,
    clearFields,
    hasChanges,
    validatePdfFile,
    selectedCourt,
    setSelectedCourt,
    extractedData,
    editExtractedFile,
    blockTypeSwitch,
    getLogoPositions,
    validateForm,
    clearErrorStates,
    clearSessionStorage,
    uploadKey,
    setUploadKey,
    setIsExtractActive,
  } = useContext(extractContext);

  const { setOverlay } = useContext(overlayContext);

  const getCourtName = (court) => {
    switch (court) {
      case "City":
        return "Byret";
      case "National":
        return "Landsret";
      case "Supreme":
        return "Højesteret";
      default:
        break;
    }
  };

  const onUploadedFile = (files) => {
    let filesToUpload = [];

    for (let i = 0; i < files.length; i++) {
      if (files[i].type !== "application/pdf") {
        continue;
      }

      let name = files[i].name;

      let file = {
        id: getUuid(),
        name: name,
        content: null,
        date: "",
        isValid: null,
        isUploaded: false,
        currentChunkIndex: 0,
        totalChunks: 0,
      };

      setUploadedFiles((prev) => [...prev, file]);
      filesToUpload.push({ id: file.id, file: files[i] });
    }

    if (filesToUpload.length > 0) {
      setFilesToUpload(filesToUpload);
    }
  };

  const hiddenInputRef = useRef(null);

  const urlChangeHandler = (url, replace = false) => {
    const historyMethod = replace ? "replaceState" : "pushState";
    window.history[historyMethod](null, "", url);
  };

  const enterValuesInHiddenInput = () => {
    if (hiddenInputRef.current && hiddenInputRef.current.onClick) {
      hiddenInputRef.current.onClick();
    }
  };

  useEffect(() => {
    urlChangeHandler(window.location.href);
    enterValuesInHiddenInput();
  }, []);

  useEffect(() => {
    enterValuesInHiddenInput();
  
    window.addEventListener("popstate", handlePopState);
    window.history.pushState({ modalOpened: false }, "");

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [hiddenInputRef]);

  const handlePopState = () => {
    navigateBack(
      setOverlay,
      () => {
        navigate("/")
      },
      () => {
        window.history.pushState({ modalOpened: false }, "");
      })
  };

  useDidMountEffect(
    () => {
      if (
        courtNumber !== "" ||
        nameOfPlaintiff !== "" ||
        cvrOfPlaintiff !== "" ||
        addressOfPlaintiff !== "" ||
        lawyerOfPlaintiff !== "" ||
        nameOfDefendant !== "" ||
        addressOfDefendant !== "" ||
        lawyerOfDefendant !== "" ||
        highCourtText !== "" ||
        cvrOfDefendant !== "" ||
        isPlaintiffLawyerAppointed !== false ||
        isDefendantLawyerAppointed !== false ||
        numberOfJudges !== null ||
        selectedStartDate !== undefined
      ) {
        sessionStorage.removeItem("case_info_form");
        addToSession("case_info_form", {
          caseInfoFormOpened: showCaseInfoForm,
          courtNumber: courtNumber,
          nameOfPlaintiff: nameOfPlaintiff,
          cvrOfPlaintiff: cvrOfPlaintiff,
          addressOfPlaintiff: addressOfPlaintiff,
          lawyerOfPlaintiff: lawyerOfPlaintiff,
          nameOfDefendant: nameOfDefendant,
          addressOfDefendant: addressOfDefendant,
          lawyerOfDefendant: lawyerOfDefendant,
          highCourtText: highCourtText,
          cvrOfDefendant: cvrOfDefendant,
          isPlaintiffLawyerAppointed: isPlaintiffLawyerAppointed,
          isDefendantLawyerAppointed: isDefendantLawyerAppointed,
          numberOfJudges: numberOfJudges,
          selectedStartDate: selectedStartDate,
        });
      }
    },
    () => {},
    [
      showCaseInfoForm,
      courtNumber,
      nameOfPlaintiff,
      cvrOfPlaintiff,
      addressOfPlaintiff,
      lawyerOfPlaintiff,
      nameOfDefendant,
      addressOfDefendant,
      lawyerOfDefendant,
      highCourtText,
      cvrOfDefendant,
      isPlaintiffLawyerAppointed,
      isDefendantLawyerAppointed,
      numberOfJudges,
      selectedStartDate,
    ]
  );

  useDidMountEffect(
    () => {
      if (
        selectedImage !== "" ||
        selectedLogoPosition !== false ||
        selectedPdf !== "" ||
        addTimeline !== false ||
        printBothSide !== false
      ) {
        sessionStorage.removeItem("advanced_form");
        addToSession("advanced_form", {
          advancedFormOpened: showAdvancedForm,
          selectedImage: selectedImage,
          selectedLogoPosition: selectedLogoPosition,
          selectedPdf: selectedPdf,
          addTimeline: addTimeline,
          printBothSide: printBothSide,
        });
      }
    },
    () => {},
    [
      showAdvancedForm,
      selectedImage,
      selectedLogoPosition,
      selectedPdf,
      addTimeline,
      printBothSide,
    ]
  );

  useEffect(() => {
    const files = [...filesToUpload];
    if (files.length > 0) {
      OnUploadFiles(uploadKey, files[0], 0, files.slice(1, files.length));
    }
  }, [filesToUpload]);

  const OnUploadFiles = (
    localUploadKey,
    fileToUpload,
    currentChunkIndex,
    remainingFiles
  ) => {
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = fileToUpload.file.slice(from, to);

    let validateHeaders = {
      "Content-Type": "application/octet-stream",
      Accept: "application/json",
      "NemEkstrakt-FileUpload-Key": localUploadKey ?? null,
      "NemEkstrakt-FileUpload-FileName": encodeURIComponent(
        `${fileToUpload.id}_${fileToUpload.file.name}`
      ),
      "NemEkstrakt-FileUpload-Current": currentChunkIndex,
      "NemEkstrakt-FileUpload-Total": Math.ceil(
        fileToUpload.file.size / chunkSize
      ),
      "NemEkstrakt-FileUpload-Size": chunkSize,
    };

    validatePdfFile(validateHeaders, blob, (success, response) => {
      let file = uploadedFiles.find((f) => f.id === fileToUpload.id);
      if (success) {
        file.content = response.reference;
        setUploadKey(uploadKey === null ? response.key : uploadKey);
        if (response.isUploaded !== true) {
          OnUploadFiles(response.key, fileToUpload, currentChunkIndex + 1, []);
        }

        for (let i = 0; i < remainingFiles.length; i++) {
          OnUploadFiles(response.key, remainingFiles[i], 0, []);
        }
      } else {
        if (remainingFiles.length > 0) {
          OnUploadFiles(
            localUploadKey,
            remainingFiles[0],
            0,
            remainingFiles.slice(1, remainingFiles.length)
          );
        }
      }
      file.currentChunkIndex = currentChunkIndex;
      file.totalChunks = Math.ceil(fileToUpload.file.size / chunkSize);

      file.isTextSearchable = response.isTextSearchable;
      file.isUploaded = success === true && response.isUploaded === true;
      file.isValid = success;
      file.error = success ? null : "Fejl ved læsning af PDF-fil";

      if (success) {
        sessionStorage.removeItem("uploaded_files");
        addToSession(
          "uploaded_files",
          uploadedFiles.filter((uploadedFile) => uploadedFile.content)
        );
      }

      setUploadedFiles((prev) =>
        prev.map((f) => {
          if (f.id === fileToUpload.id) {
            return file;
          } else {
            return f;
          }
        })
      );
    });
  };

  const validateDates = () => {
    let isValid = true;
    headers.forEach((header) => {
      if (header.isMultiple) {
        header?.usedFiles?.forEach((file) => {
          if (!file.date) {
            isValid = false;
          }
        });
      }
    });
    return isValid;
  };

  const validateRequiredItems = () => {
    let isValid = true;
    headers.forEach((header) => {
      if (header.required) {
        header.required.forEach((item) => {
          if (
            item.content !== null && item.content !== undefined ? false : true
          ) {
            isValid = false;
          }
        });
      }
    });
    return isValid;
  };

  const validateMandatoryHeaders = () => {
    let isValid = true;
    headers.forEach((header,i) => {
      if (!header.isMultiple) {
        if (
          header.usedFiles?.[0]?.content === null ||
          header.usedFiles?.[0]?.content === undefined
        ) {
          if(headerItemsRef.current[i]){
            headerItemsRef.current[i].scrollIntoView({ behavior: "smooth", block:"center" });
          }
          isValid = false;
        }
      } else {
        if (
          !header.required &&
          header.isMandatory &&
          ((header?.usedFiles?.length ?? 0) === 0 ||
            (header?.usedFiles?.length > 0 &&
              header?.usedFiles?.find(
                (f) => f.content === null || f.content === undefined
              )))
        ) {
          if(headerItemsRef.current[i]){
            headerItemsRef.current[i].scrollIntoView({ behavior: "smooth", block:"center" });
          }
          isValid = false;
        }
        if(header?.usedFiles?.find(
          (f) => f.date === ""
        )){
          if(headerItemsRef.current[i]){
            headerItemsRef.current[i].scrollIntoView({ behavior: "smooth", block:"center" });
          }
        }
      }
    });
    return isValid;
  };

  const onItemEdit = (id, value, date) => {
    //unused
    let unusedFilesArray = uploadedFiles;
    const containsUnusedFile = unusedFilesArray.find((item) => item.id === id);
    if (containsUnusedFile) {
      unusedFilesArray = unusedFilesArray.map((item) => ({
        ...item,
        name: item.id === id && value !== null ? value : item.name,
        date: item.id === id && date !== null ? date : item.date,
      }));
      setUploadedFiles(unusedFilesArray);
      return;
    }
    // used
    let usedFilesArray = headers;
    const containsUsedFile = usedFilesArray.find((header) =>
      header.usedFiles.find((item) => item.id === id)
    );
    if (containsUsedFile) {
      usedFilesArray = usedFilesArray.map((header) => ({
        ...header,
        usedFiles: header.usedFiles.map((item) => ({
          ...item,
          name: item.id === id && value !== null ? value : item.name,
          date: item.id === id && date !== null ? date : item.date,
        })),
      }));
      setHeaders(usedFilesArray);
      return;
    }
  };

  const onSortClick = (name) => {
    let usedFilesArray = headers;
    const containsUsedFile = usedFilesArray.find(
      (header) => header.id === name
    );
    if (containsUsedFile) {
      usedFilesArray = usedFilesArray.map((header) => ({
        ...header,
        usedFiles: sortArrayByDate(header.usedFiles, header.id === name),
      }));
      setHeaders(usedFilesArray);
      return;
    }
  };

  const sortArrayByDate = (array, canSort) => {
    if (canSort && array?.length > 0) {
      return array.sort((objA, objB) => Number(objB.date) - Number(objA.date));
    } else {
      return array;
    }
  };

  const onItemDelete = (id) => {
    //unused
    let unusedFilesArray = uploadedFiles;
    const containsUnusedFile = unusedFilesArray.find((item) => item.id === id);
    if (containsUnusedFile) {
      unusedFilesArray = unusedFilesArray.filter((item) => item.id !== id);
      sessionStorage.removeItem("uploaded_files");
      addToSession("uploaded_files", unusedFilesArray);
      setUploadedFiles(unusedFilesArray);
      return;
    }
    // used
    let usedFilesArray = headers;
    const containsUsedFile = usedFilesArray.find((header) =>
      header.usedFiles.find((item) => item.id === id)
    );
    if (containsUsedFile) {
      usedFilesArray = usedFilesArray.map((header) => ({
        ...header,
        usedFiles: header.usedFiles.filter((item) => item.id !== id),
      }));
      setHeaders(usedFilesArray);
      return;
    }
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    let add;
    let unusedFilesArray = uploadedFiles;
    let usedFilesArray = headers;
    if (source.droppableId === "UploadedFileList") {
      add = unusedFilesArray[source.index];
      unusedFilesArray.splice(source.index, 1);
      sessionStorage.removeItem("uploaded_files");
      addToSession("uploaded_files", unusedFilesArray);
    } else {
      for (let i = 0; i < usedFilesArray.length; i++) {
        if (usedFilesArray[i].id === source.droppableId) {
          add = usedFilesArray[i].usedFiles[source.index];
          usedFilesArray[i].usedFiles.splice(source.index, 1);
        }
      }
    }

    if (destination.droppableId === "UploadedFileList") {
      unusedFilesArray.splice(destination.index, 0, add);
    } else {
      for (let i = 0; i < usedFilesArray.length; i++) {
        if (usedFilesArray[i].id === destination.droppableId) {
          if (
            !usedFilesArray[i].isMultiple &&
            usedFilesArray[i].usedFiles.length > 0
          )
            return;
          usedFilesArray[i].usedFiles.splice(destination.index, 0, add);
        }
      }
    }

    setUploadedFiles([...unusedFilesArray]);
  };

  const loadExtractedData = (data) => {
    let extHeaders = data.documentation.map((header) => ({
      id: header.header.name,
      order: header.header.order,
      usedFiles: header.material.map((file) => ({
        ...file,
        isValid: true,
        isUploaded: true,
        date: new Date(file.date),
        content: "",
      })),
      isMandatory: header.header.isMandatory,
      isMultiple: header.header.isMultiple,
      isSortable: header.header.isSortable,
    }));
    setHeaders(extHeaders);
  };

  useEffect(() => {
    setIsExtractActive(true);
    let courtTypeFromSession = getFromSession("court_type");
    if (courtTypeFromSession?.type !== selectedCourt) {
      clearSessionStorage();
    }
    let uploadedFilesFromSession = getFromSession("uploaded_files");
    let headersFromSession = getFromSession("headers_data");

    if (
      uploadedFilesFromSession !== undefined &&
      uploadedFilesFromSession !== null
    ) {
      setUploadedFiles(uploadedFilesFromSession.filter((file) => file.content));
    }

    clearErrorStates();
    setShowFileErrors(false);
    getLogoPositions();
    if (extractedData) {
      //preloading exptracted data from context
      loadExtractedData(extractedData);
      setEditMode(true);
      return;
    }

    sessionStorage.removeItem("court_type");
    addToSession("court_type", { type: selectedCourt });

    getHeaders(selectedCourt, (success, headers) => {
      if (success && (courtTypeFromSession?.type !== selectedCourt || !headersFromSession)) {
        setHeaders(headers);
      } else {
        setHeaders(headersFromSession);
      }
    });

    return () => {
      setIsExtractActive(false);
    };
  }, [selectedCourt]);

  return (
    <div className="w-full h-full flex flex-col px-[40px]">
      <div className="w-full  flex mt-[15px] overflow-hidden">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="w-full h-full">
            <div className="text-primaryBlue text-standardXLarge font-latoBold mb-[16px]">
              Alle dokumenter
            </div>
            <UploadPDF ref={uploadFileRef} onUploadedFile={onUploadedFile} />
            <div className="h-[calc(100vh-310px)] overflow-auto">
              <DroppableList
                droppableId="UploadedFileList"
                fileList={uploadedFiles}
                userInfo={userInfo}
                onItemDelete={onItemDelete}
                onItemEdit={onItemEdit}
              />
            </div>
          </div>

          <div className="w-full h-full ml-[40px]">
            <div className="flex flex-row w-full justify-between mb-[16px] text-primaryBlue text-standardXLarge font-latoBold">
              <p>
                Din ekstrakt{" "}
                <span className="font-latoLight text-[#A0A0A0]">
                  (træk dine dokumenter herover, når du har uploadet)
                </span>
              </p>

              <span>{getCourtName(selectedCourt)}
                <span className="ml-[5px] text-[#A0A0A0] text-standard font-latoLight">
                  (&nbsp;<Link className="underline" to={`/guidelines/${getCourtName(selectedCourt)}.pdf`} target="_blank" download>retningslinje</Link>&nbsp;)
                </span>
              </span>
            </div>
            <div className="h-[calc(100vh-170px)] overflow-auto bg-primaryBlue bg-opacity-[6%]">
              <ExtractContent
                showCaseInfoForm={showCaseInfoForm}
                setShowCaseInfoForm={setShowCaseInfoForm}
                showAdvancedForm={showAdvancedForm}
                setShowAdvancedForm={setShowAdvancedForm}
                showFileErrors={showFileErrors}
                setShowFileErrors={setShowFileErrors}
                selectedCourt={selectedCourt}
                setSelectedCourt={setSelectedCourt}
                headers={headers}
                onSortClick={onSortClick}
                onItemDelete={onItemDelete}
                onItemEdit={onItemEdit}
                editExtractedFile={editExtractedFile}
                hasChanges={hasChanges}
                clearFields={clearFields}
                margeDocuments={margeDocuments}
                mergeUploadKey={uploadKey}
                editMode={editMode}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                isCaseInfoFormFilled={isCaseInfoFormFilled}
                blockTypeSwitch={blockTypeSwitch}
                validateDates={validateDates}
                validateRequiredItems={validateRequiredItems}
                validateMandatoryHeaders={validateMandatoryHeaders}
                validateForm={validateForm}
              />
            </div>
          </div>
        </DragDropContext>
      </div>
      <button ref={hiddenInputRef} style={{ visibility: "hidden" }}>
        Test
      </button>
    </div>
  );
}
