import SubscriptionCard from "app/Homepage/PageComponents/Subscription/SubscriptionCard";
import Popup from "./Popup";
import usePayment from "_data/hooks/payment/usePayment";
import { useState } from "react";
import { AppConfig } from "AppConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logEvent } from "analytics/analytics";

export default function SubscriptionUpgradePopup({
  hideOverlay,
  userInfo,
  refreshUser,
}) {
  const { initiatePayment, getSubscriptionTypes } = usePayment();
  const [isYearSubscription, setIsYearSubscription] = useState(false);
  const navigate = useNavigate();

  const openNewTab = () => {
    window.open(AppConfig.APP_WEBSITE_KONTAKT, "_blank");
  };

  const subscribe = () => {
    initiatePayment(isYearSubscription ? "Year" : "Month", (success) => {
      if (!success) {
        toast.error("Betaling mislykkedes");
      }
      refreshUser();
    });
  };

  return (
    <Popup styles={"w-[80%] h-[96%] tall:h-[90%] py-[0px]"}>
      <div className="flex flex-col w-full h-full justify-around items-center gap-y-4 py-0 relative">
        <img
          className="absolute content-darkCloseIcon top-0 right-0 m-5 cursor-pointer"
          alt=""
          onClick={() => hideOverlay()}
        />
        <p className="text-primaryBlue font-latoBold text-[20px]">
          Opgradér din løsning
        </p>
        <div className="flex flex-row w-full h-[90%] tall:h-[85%] justify-center gap-x-5 px-5 pb-2">
          <SubscriptionCard
            styles={"bg-lightBrown"}
            license={"Freemium"}
            price={"Gratis"}
            description={"Til advokater, der ønsker\n at afprøve før køb."}
            unavailableItems={["50 siders begrænsning","Ændringer til oprettet ekstrakt ikke muligt"]}
            items={["Byret",
              "Landsret",
              "Højsteret",
              "Logo",
              "Brevpapir",
              "Tidslinje",
              "Klikbart index",
              "Bogmærker",
              "Søgbar PDF-tjek"]}
            onClick={() => hideOverlay()}
          />
          <SubscriptionCard
            isYearSubscription={userInfo?.subscriptionType === "Year"}
            setIsYearSubscription={setIsYearSubscription}
            styles={"bg-[#F0F3F5]"}
            license={"Premium"}
            description={
              "Til advokater, der ikke har\n behov for en entrepriseløsning."
            }
            items={[
              "Byret",
              "Landsret",
              "Højsteret",
              "Logo",
              "Brevpapir",
              "Tidslinje",
              "Klikbart index",
              "Bogmærker",
              "Søgbar PDF-tjek",
            ]}
            hasToggle={true}
            isButtonDisabled={
              userInfo?.executionLicense === "Premium" && userInfo?.subscriptionStatus === "Active"
            }
            buttonText={userInfo?.license === "Freemium" ? "køb nu" : "Forny"}
            onClick={() => {
              logEvent("subscription","app_upgrade_start","subscribe_button");
              hideOverlay();
              if (!userInfo.hasBillingAddress || !userInfo.hasAcceptedTerms ) {
                navigate("billing", {
                  state: { subscriptionType: isYearSubscription ? "Year" : "Month" },
                });
              } else {
                subscribe();
              }
            }}
          />
          <SubscriptionCard
            styles={"bg-[#C58B68] bg-opacity-[16%]"}
            license={"Enterprise"}
            price={"Lad os tale om det"}
            description={"Til advokater, er har brug for særlig tilpasning"}
            items={["Lokal installation", "Variabel kapacitet", "Tilpasning"]}
            buttonText={"KONTAKT FOR TILBUD"}
            onClick={() => openNewTab()}
          />
        </div>
      </div>
    </Popup>
  );
}
