export default function CompanyPromo({show = false, onClick}) {
  return (
    show && <div className="flex flex-row gap-[6px] absolute bottom-2 left-12">
      <img
        alt="SoftLab"
        className="content-softlabIcon cursor-pointer"
        onClick={onClick}
      />
      <p className="text-sm">Solutions</p>
    </div>
  );
}
