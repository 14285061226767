import { extractContext } from "_data/context/extract";
import React, { useContext, useRef, useState } from "react";
import UploadFile from "__utilities/components/UploadFile";
import { fileToBase64 } from "__utilities/functions/public";
import CheckBox from "__utilities/components/CheckBox";
import InputField from "__utilities/components/InputField";
import AppTooltip from "__utilities/components/Tooltip";
import "react-tooltip/dist/react-tooltip.css";

function AdvancedForm({
  isEnabledLogo,
  isEnabledLetterhead,
  isEnabledTimeline,
}) {
  const {
    companyLogoError,
    companyLetterheadError,
    addTimeline,
    setAddTimeline,
    selectedImage,
    selectedPdf,
    setSelectedImage,
    setSelectedPdf,
    printBothSide,
    setPrintBothSide,
    selectedCourt,
    selectedLogoPosition,
    setSelectedLogoPosition,
    logoPositions,
  } = useContext(extractContext);
  const companyLogoImageRef = useRef();
  const companyLetterheadPdfRef = useRef();

  const onUploadedImage = (image) => {
    let name = image[0].name;
    fileToBase64(
      image[0],
      (error, result) => {
        if (error) return;
        let img = { name: name, content: result };
        setSelectedImage(img);
      },
      image[0].type
    );
  };

  const onUploadedLetterhead = (pdf) => {
    let name = pdf[0].name;
    fileToBase64(
      pdf[0],
      (error, result) => {
        if (error) return;
        let pdf = { name: name, content: result };
        setSelectedPdf(pdf);
      },
      pdf[0].type
    );
  };

  return (
    <div className="flex flex-col gap-[8px] mt-[14px]">
      <UploadFile
        ref={companyLogoImageRef}
        onUploadedFile={onUploadedImage}
        multipleSelect={false}
        tooltipContent="Kun tilgængelig for Premium-brugere!"
        type={"image/png, image/jpeg"}
      >
        <InputField
          name="Virksomhedslogo:"
          selectedCourt={selectedCourt}
          error={companyLogoError}
          floatingContent={
            <div
              className={`flex gap-[4px] text-primaryBlue`}
            >
              {selectedImage ? (
                <>
                  <img
                    className="content-attachedIcon"
                    alt=""
                  />
                  {selectedImage.name}
                </>
              ) : (
                <div className="underline">Upload venligst</div>
              )}
            </div>
          }
          clearContent={
            selectedImage && (
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedImage("");
                }}
                className="content-trashIcon"
                alt=""
              />
            )
          }
        />
      </UploadFile>
      <InputField
        name="Angiv logoets placering:"
        tooltipContent="Kun tilgængelig for Premium-brugere!"
        tooltipId={""}
        floatingContent={
          <div className="flex flex-row gap-x-5">
            {logoPositions.map((element) => (
              <div
                data-tooltip-content={element.value}
                data-tooltip-id="logo-position-tooltip"
                key={element.key}
                onClick={() =>
                  setSelectedLogoPosition(element)
                }
                className={`p-[2px] ${
                  element.key === selectedLogoPosition?.key && "bg-primaryBlue bg-opacity-[15%] text-white"} cursor-pointer`}
              >
                <img
                  className={`${element.icon}`}
                  alt=""
                />
              </div>
            ))}
          </div>
        }
        selectedCourt={selectedCourt}
      />
      <UploadFile
        ref={companyLetterheadPdfRef}
        onUploadedFile={onUploadedLetterhead}
        multipleSelect={false}
        tooltipContent="Kun tilgængelig for Premium-brugere!"
        type={"application/pdf"}
      >
        <InputField
          name="Brevpapir:"
          selectedCourt={selectedCourt}
          error={companyLetterheadError}
          floatingContent={
            <div
              className={`flex gap-[4px] text-primaryBlue`}
            >
              {selectedPdf ? (
                <>
                  <img
                    className="content-attachedIcon"
                    alt=""
                  />
                  {selectedPdf.name}
                </>
              ) : (
                <div className="underline">Upload pdf</div>
              )}
            </div>
          }
          clearContent={
            selectedPdf && (
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedPdf("");
                }}
                className="content-trashIcon"
                alt=""
              />
            )
          }
        />
      </UploadFile>

      <InputField
        name="Tilføj tidslinje:"
        selectedCourt={selectedCourt}
        floatingContent={
          <CheckBox
            isChecked={addTimeline}
            onChange={() =>
              setAddTimeline((prev) => !prev)
            }
          />
        }
      />
      {selectedCourt === "National" && (
        <InputField
          name="Udskriv på begge sider af papiret:"
          selectedCourt={selectedCourt}
          floatingContent={
            <CheckBox
              isChecked={printBothSide}
              onChange={() => setPrintBothSide((prev) => !prev)}
            />
          }
        />
      )}

      <AppTooltip
        componentIds={[
          "upload-logo-tooltip",
          "tidslinje-tooltip",
          "logo-position-tooltip",
        ]}
      />
    </div>
  );
}

export default AdvancedForm;
