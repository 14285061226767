import CustomButton from "__utilities/components/CustomButton";
import Section from "__utilities/components/Section";
import SubscriptionUpgradePopup from "__utilities/components/SubscriptionUpgradePopup";
import { addToSession } from "__utilities/functions/sessionHandler";
import useDidMountEffect from "__utilities/functions/useDidMount";
import { extractContext } from "_data/context/extract";
import { overlayContext } from "_data/context/overlay";
import usePayment from "_data/hooks/payment/usePayment";
import AdvancedForm from "_features/AdvancedForm";
import CaseInfoForm from "_features/CaseInfoForm";
import DroppableItem from "_features/DroppableItem";
import DroppableList from "_features/DroppableList";
import { logEvent } from "analytics/analytics";
import React, { useContext, useRef, useState } from "react";

export default function ExtractContent({
  headers,
  showCaseInfoForm,
  setShowCaseInfoForm,
  showAdvancedForm,
  setShowAdvancedForm,
  onSortClick,
  onItemDelete,
  onItemEdit,
  editExtractedFile,
  margeDocuments,
  mergeUploadKey,
  editMode,
  userInfo,
  setUserInfo,
  isCaseInfoFormFilled,
  showFileErrors,
  setShowFileErrors,
  validateDates,
  validateRequiredItems,
  validateMandatoryHeaders,
  validateForm
}) {
  const [showCaseInfoFormLocal, setShowCaseInfoFormLocal] = useState(showCaseInfoForm);
  const {
		headerItemsRef
	} = useContext(extractContext);

  const { setOverlay } = useContext(overlayContext);
  const { getUserInfoData } = usePayment();
  const caseInfoFormRef = useRef(null);
  const showMandatoryFields = () => {
    setShowCaseInfoForm(true);
    validateForm()
  };

  const refreshUserInfo = async () => {
    await getUserInfoData((success, userInfo) => {
      if (success) {
        setUserInfo(userInfo);
      }
    });
  };

  useDidMountEffect(
    () => {
      sessionStorage.removeItem("headers_data");
      addToSession("headers_data", headers);
    },
    () => {},
    [headers]
  );

  return (
    <div ref={caseInfoFormRef} className="px-[40px] py-[30px]">
      <Section
        title="Sagsoplysninger:"
        isOpen={showCaseInfoFormLocal ? showCaseInfoFormLocal : false}
        setIsOpen={setShowCaseInfoFormLocal}
        styles="mb-[30px]"
      >
        <CaseInfoForm />
      </Section>
      <Section
        title="Layout:"
        isOpen={showAdvancedForm ? showAdvancedForm : false}
        setIsOpen={setShowAdvancedForm}
        styles="mb-[30px]"
      >
        <AdvancedForm />
      </Section>
      <div className="mb-[16px] text-primaryBlue text-standardXLarge font-latoBold">
        Dokumentation:
      </div>

      <div className="mb-[30px]">
        {headers?.map((header,i) => {
          if (!header.isMultiple) {
            return (
              <DroppableItem
                key={header.id}
                droppableId={header.id}
                item={{
                  id: header.usedFiles?.[0]?.id,
                  name: header.id,
                  index: 0,
                  fileName: header.name,
                }}
                hasPlaceholder={true}
                userInfo={userInfo}
                isSelected={
                  header.usedFiles?.[0]?.content !== null &&
                  header.usedFiles?.[0]?.content !== undefined
                    ? true
                    : false
                }
                onItemDelete={onItemDelete}
                error={
                  (header.usedFiles?.[0]?.content === null ||
                    header.usedFiles?.[0]?.content === undefined) &&
                  showFileErrors
                    ? true
                    : false
                }
                ref={ref => {
                  headerItemsRef.current[i] = ref
                }}
              />
            );
          } else {
            return (
              <DroppableList
                key={header.id}
                header={header.id}
                isSortable={header.isSortable}
                onSortClick={onSortClick}
                droppableId={header.id}
                fileList={header.usedFiles}
                styles={`mb-[12px]`}
                hasPlaceholder={true}
                userInfo={userInfo}
                onItemDelete={onItemDelete}
                onItemEdit={onItemEdit}
                elementsHasError={true}
                listHasError={
                  !header.required &&
                  header.isMandatory &&
                  ((header?.usedFiles?.length ?? 0) === 0 ||
                    (header?.usedFiles?.length > 0 &&
                      header?.usedFiles?.find(
                        (f) => f.content === null || f.content === undefined
                      ))) &&
                  showFileErrors
                    ? true
                    : false
                }
                listHasDate={true}
                ref={ref => {
                  headerItemsRef.current[i] = ref
                }}
              />
            );
          }
        })}
      </div>
      {userInfo?.executionLicense === "Freemium" && <div className="flex w-full justify-start items-center gap-3 text-start border border-orange-400 bg-lightBrown mb-2 py-1 px-2">
        <div className="flex w-[4%] justify-center items-center">
        <div className="flex w-6 h-6 justify-center items-center ">
          <img className="content-infoOrangeIcon" />
          </div>
        </div>
        
        <p className="w-4/5 text-[13px] tall:text-sm">Din ekstrakt vil være begrænset til 50 sider.<br /> Opgrader til <span className="text-blue-600 underline cursor-pointer" onClick={() => setOverlay({
                  show: true,
                  content: (
                    <SubscriptionUpgradePopup
                      hideOverlay={() =>
                        setOverlay({ show: false, content: <></> })
                      }
                      userInfo={userInfo}
                      refreshUser={() => refreshUserInfo()}
                    />
                  ),
                })}>premium</span> for at fjerne begrænsningen og få det fulde udbytte af værktøjet.</p>
      </div>}
      <CustomButton
        text="OPRET EKSTRAKT"
        styles="text-standardSmall uppercase"
        onClick={() => {
          logEvent("create_new_extract","app_extract_create","extract_button");
          setShowFileErrors(true);
          
          if(!showCaseInfoFormLocal && !isCaseInfoFormFilled){
            setShowCaseInfoFormLocal(true)
            caseInfoFormRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
              });
              validateForm()
              return;
          }
          let isValid = validateForm();

          isValid &= validateDates();
          isValid &= validateRequiredItems();
          isValid &= validateMandatoryHeaders();
          !isValid && showMandatoryFields();
          if (!isValid) return;

          if (editMode) {
            editExtractedFile(headers);
          } else {
            margeDocuments(mergeUploadKey, headers);
          }
        }}
      />
    </div>
  );
}
