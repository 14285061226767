import React, { useContext, useEffect, useState } from "react";
import ScreenOverlay from "__utilities/components/ScreenOverlay";
import { overlayContext } from "_data/context/overlay";
import withAuth from "msal/MsalAuthProvider";
import { AppConfig } from "AppConfig";
import Header from "./Homepage/PageComponents/Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import usePayment from "_data/hooks/payment/usePayment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { navigateTo } from "_navigation/manager";
import { initGA, logPageView } from "analytics/analytics";
import CompanyPromo from "__utilities/components/CompanyPromo";

function Main() {
  initGA(AppConfig.APP_GOOGLE_ANALYTICS);
  const location = useLocation();
  
  const { overlay, setOverlay, overlayLock } = useContext(overlayContext);
  const { getUserInfoData } = usePayment();
  const [userInfo, setUserInfo] = useState({});
  const [isExtractActive, setIsExtractActive] = useState(false);
  const [showCourts, setShowCourts] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    logPageView(location.pathname)
  },[location])

  useEffect(() => {
    refreshUserInfo();
  }, []);

  const refreshUserInfo = async () => {
    await getUserInfoData((success, userInfo) => {
      if (success) {
        setUserInfo(userInfo);
      }
    });
  };
  const navigate = useNavigate();

  const openSite = () => {
    window.open(
      AppConfig.APP_WEBSITE_SOFTLAB,
      "_blank",
    );
  }

  const contextValue = {
    userInfo,
    setUserInfo,
    showCourts,
    setShowCourts,
    isExtractActive,
    setIsExtractActive,
  };

  return (
    <div className="w-full h-full flex flex-col">
      <Header
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        isExtractActive={isExtractActive}
        setIsExtractActive={setIsExtractActive}
        navigateToHome={() => {
          navigateTo(setOverlay, isExtractActive, setIsExtractActive, () => {
            setShowCourts(false);
            navigate("/");
            window.location.reload();
          });
        }}
      />
      <div
        className="w-full h-full relative"
        onClick={() => setShowDropdown(false)}
      >
        <div className="w-full h-full overflow-y-auto absolute">
          <Outlet context={contextValue} />
        </div>
      </div>
      <CompanyPromo show={false} onClick={() => openSite()}/>
      {overlay.show && (
        <ScreenOverlay
          hideOverlay={() => setOverlay({ show: false, content: <></> })}
          overlayLock={overlayLock}
          content={overlay.content}
        />
      )}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        pauseOnHover
        closeOnClick
      />
    </div>
  );
}

export default AppConfig.AUTH_ENABLED === true ? withAuth(Main) : Main;
